import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/dist/client/components/render-from-template-context.js");
